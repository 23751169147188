<template>
  <FormulateForm
    ref="cardForm"
    name="cardForm"
  >
    <b-card-actions
      :title="$t('Dados do cartão')"
      no-actions
      :show-loading="saving"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="card-type"
            v-model="formCard.type"
            :label="$t('Tipo de cartão')"
            name="type"
            type="vue-select"
            :placeholder="$t('Selecione')"
            :options="cardTypeOptions()"
            class="required"
            validation="required"
            :disabled="isEdition"
            :clearable="false"
            @input="onChangeIntallmentsType"
          />
        </b-col>
        <b-col>
          <FormulateInput
            id="card-name"
            v-model="formCard.name"
            :label="$t('Bandeira')"
            name="name"
            type="vue-select"
            class="required"
            validation="required"
            :placeholder="$t('Selecione')"
            :options="cardBrands()"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="card-acquirer"
            v-model="formCard.acquirer"
            :label="$t('Adquirente')"
            name="acquirer"
            type="vue-select"
            class="required"
            validation="required"
            :placeholder="$t('Selecione')"
            :instruction="$t('Adquirente da maquininha de cartão')"
            :options="acquirerOptions()"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="card-days-to-receive"
            v-model="formCard.daysToReceive"
            :label="$t('Dias para recebimento')"
            name="daysToReceive"
            type="number"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <FormulateInput
            id="image"
            v-model="imageLocal"
            type="uploader"
            deletable="true"
            max-files="1"
            :meta="false"
            :label="$t('Imagem')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="card-round-installment"
            v-model="formCard.roundInstallment"
            :label="$t('Parcela a arredondar')"
            name="roundInstallment"
            type="vue-select"
            class="required"
            validation="required"
            :instruction="
              $t('Onde será o arredondamento do valor da parcela caso haja diferença entre elas')
            "
            :options="roundInstallmentOptions()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="card-active"
            v-model="formCard.active"
            :label="$t('Ativo?')"
            name="active"
            type="switch"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="card-online_active"
            v-model="formCard.onlineActive"
            :label="$t('Ativo online?')"
            name="onlineActive"
            type="switch"
          />
        </b-col>
        <!-- <b-col md="3">
          <FormulateInput
            id="card-fee-per-product"
            v-model="formCard.feePerProduct"
            :label="$t('Taxa por produto?')"
            name="feePerProduct"
            type="switch"
          />
        </b-col> -->
      </b-row>
    </b-card-actions>

    <b-card-actions
      no-actions
      :show-loading="saving"
    >
      <b-row class="mb-1">
        <b-col class="d-flex justify-content-between align-items-center">
          <p class="h4">
            {{ $t('Formas de parcelamento') }}
          </p>

          <b-button
            :disabled="formCard.type == 'DEBIT'"
            variant="primary"
            :title="$t('Adicionar forma de parcelamento')"
            class="px-1"
            @click="addInstForm"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            ref="tableInstallments"
            show-empty
            responsive
            striped
            class="bordered"
            :fields="paymentFormsFields"
            :items="formCard.installmentForms.filter((p) => !p.isDeleted)"
            :empty-text="$t('Não há registros para exibir')"
          >
            <template #cell(minSaleValue)="row">
              {{ row.item.minSaleValue | currency }}
            </template>

            <template #cell(fee)="row">
              {{ row.item.fee | percentage }}
            </template>

            <template #cell(additionalFee)="row">
              {{ row.item.additionalFee | percentage }}
            </template>

            <template #cell(action)="row">
              <e-grid-actions
                :show-update="true"
                :show-delete="formCard.type == 'DEBIT' ? false : true"
                @update="updateInstForm(row)"
                @delete="removeInstForm(row)"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card-actions>

    <e-crud-actions
      :busy="saving"
      @save="onSubmit"
      @cancel="onCancel"
    />

    <installment-form-sidebar
      ref="installmentFormSidebar"
      :is-debit="formCard.type === cardTypeEnum.DEBIT"
    />
  </FormulateForm>
</template>

<script>
import { BRow, BCol, BTable, BButton } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState } from 'vuex'
import { cardDomain, uploader } from '@/mixins'
import ECrudActions from '@/views/components/ECrudActions.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import InstallmentFormSidebar from './InstallmentFormSidebar.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BButton,
    InstallmentFormSidebar,
    ECrudActions,
    EGridActions,
  },

  mixins: [cardDomain, uploader],

  data() {
    return {
      saving: false,
      imageLocal: [],
    }
  },

  computed: {
    ...mapState('pages/settings/financial/card/cardMaintain', ['formCard']),

    paymentFormsFields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Parcela de'),
          key: 'installmentFrom',
          class: 'text-center',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Parcela até'),
          key: 'installmentTo',
          class: 'text-center',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Taxa'),
          key: 'fee',
          class: 'text-center',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Taxa adicional'),
          key: 'additionalFee',
          class: 'text-center',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Valor mínimo'),
          key: 'minSaleValue',
          class: 'text-right',
          thStyle: { width: '150px' },
        },
      ]
    },

    isEdition() {
      return !!this.$route.params.id
    },
  },

  async mounted() {
    if (this.isEdition) {
      try {
        this.saving = true
        await this.stFetchById(this.$route.params.id)
        this.imageLocal = this.formCard?.image ? [this.getFileInstance(this.formCard.image)] : []
      } catch (error) {
        this.showError({})
      } finally {
        this.saving = false
      }
    }
  },

  methods: {
    ...mapActions('pages/settings/financial/card/cardMaintain', {
      stRemoveInstallment: 'removeInstallment',
      stAddInstallment: 'addInstallment',
      stCleanCard: 'cleanState',
      stCleanInstallments: 'cleanInstallments',
      stFetchById: 'fetchById',
      stSaveForm: 'saveForm',
    }),

    addInstForm() {
      if (this.formCard.type === 'CREDIT') {
        this.$refs.installmentFormSidebar.onShowSidebar()
      }
    },

    updateInstForm(row) {
      this.$refs.installmentFormSidebar.onShowSidebar(row)
    },

    removeInstForm(row) {
      this.stRemoveInstallment({ idOrLocalId: row.item.id || row.item.localId })
    },

    onChangeIntallmentsType() {
      if (!this.isEdition) {
        this.stCleanInstallments()
        if (this.formCard.type === 'DEBIT') {
          this.stAddInstallment({
            installmentFrom: 1,
            installmentTo: 1,
            fee: 0,
            additionalFee: 0,
            minSaleValue: 0,
            active: true,
          })
        }
      }
    },

    async onSubmit() {
      this.$refs.cardForm.showErrors()
      if (this.$refs.cardForm.hasErrors) {
        this.showInvalidDataMessage({})
        return
      }

      try {
        this.saving = true
        const [imageUploadResult] = await this.upload({
          files: this.imageLocal,
          destinationPath: `card-brands/name=${this.formCard.name}`,
        })
        if (imageUploadResult) {
          this.imageLocal = [this.getFileInstance(imageUploadResult.url)]
        }
        await this.stSaveForm(imageUploadResult?.url)
        this.showSuccess({ message: '' })
        this.$router.push({ name: 'card-list' })
        this.stCleanCard()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    onCancel() {
      this.stCleanCard()
      this.$router.push({ name: 'card-list' })
    },
  },
}
</script>

<style lang="scss" scoped></style>
