var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.edit
        ? _vm.$t("Editar Forma de Parcelamento")
        : _vm.$t("Nova Forma de Parcelamento"),
      show: _vm.showSidebar,
      fetching: _vm.loading,
      saving: _vm.saving,
      width: "500px",
    },
    on: {
      save: _vm.addInstallmentForm,
      hidden: function ($event) {
        _vm.showSidebar = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "formInstallmentForm",
                attrs: { name: "formInstallmentForm" },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "installment-installment-from",
                            label: _vm.$t("Parcela de"),
                            type: "number",
                            validation: [
                              ["min", 1],
                              ["max", _vm.formInstallmentForm.installmentTo],
                            ],
                            disabled: _vm.isDebit,
                          },
                          model: {
                            value: _vm.formInstallmentForm.installmentFrom,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInstallmentForm,
                                "installmentFrom",
                                _vm._n($$v)
                              )
                            },
                            expression: "formInstallmentForm.installmentFrom",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "installment-installment-to",
                            label: _vm.$t("Parcela até"),
                            type: "number",
                            validation: [
                              ["min", _vm.formInstallmentForm.installmentFrom],
                            ],
                            disabled: _vm.isDebit,
                          },
                          model: {
                            value: _vm.formInstallmentForm.installmentTo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInstallmentForm,
                                "installmentTo",
                                _vm._n($$v)
                              )
                            },
                            expression: "formInstallmentForm.installmentTo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "installment-fee",
                            label: _vm.$t("Taxa"),
                            type: "text-percetage",
                            currency: _vm.$t("%"),
                            precision: 2,
                            "currency-symbol-position": "suffix",
                          },
                          model: {
                            value: _vm.formInstallmentForm.fee,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInstallmentForm,
                                "fee",
                                _vm._n($$v)
                              )
                            },
                            expression: "formInstallmentForm.fee",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "installment-additional-fee",
                            label: _vm.$t("Taxa adicional"),
                            type: "text-percetage",
                            currency: _vm.$t("%"),
                            precision: 2,
                            "currency-symbol-position": "suffix",
                          },
                          model: {
                            value: _vm.formInstallmentForm.additionalFee,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInstallmentForm,
                                "additionalFee",
                                _vm._n($$v)
                              )
                            },
                            expression: "formInstallmentForm.additionalFee",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "installment-min-sale-value",
                            label: _vm.$t("Valor mínimo"),
                            type: "text-number",
                            currency: _vm.$t("R$"),
                            precision: 2,
                          },
                          model: {
                            value: _vm.formInstallmentForm.minSaleValue,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInstallmentForm,
                                "minSaleValue",
                                _vm._n($$v)
                              )
                            },
                            expression: "formInstallmentForm.minSaleValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }