<template>
  <e-sidebar
    :title="edit ? $t('Editar Forma de Parcelamento') : $t('Nova Forma de Parcelamento')"
    :show="showSidebar"
    :fetching="loading"
    :saving="saving"
    width="500px"
    @save="addInstallmentForm"
    @hidden="showSidebar = false"
  >
    <template #content>
      <FormulateForm
        ref="formInstallmentForm"
        name="formInstallmentForm"
      >
        <b-row>
          <b-col>
            <FormulateInput
              id="installment-installment-from"
              v-model.number="formInstallmentForm.installmentFrom"
              :label="$t('Parcela de')"
              type="number"
              :validation="[
                ['min', 1],
                ['max', formInstallmentForm.installmentTo],
              ]"
              :disabled="isDebit"
            />
          </b-col>
          <b-col>
            <FormulateInput
              id="installment-installment-to"
              v-model.number="formInstallmentForm.installmentTo"
              :label="$t('Parcela até')"
              type="number"
              :validation="[['min', formInstallmentForm.installmentFrom]]"
              :disabled="isDebit"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <FormulateInput
              id="installment-fee"
              v-model.number="formInstallmentForm.fee"
              :label="$t('Taxa')"
              type="text-percetage"
              :currency="$t('%')"
              :precision="2"
              currency-symbol-position="suffix"
            />
          </b-col>
          <b-col>
            <FormulateInput
              id="installment-additional-fee"
              v-model.number="formInstallmentForm.additionalFee"
              :label="$t('Taxa adicional')"
              type="text-percetage"
              :currency="$t('%')"
              :precision="2"
              currency-symbol-position="suffix"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <FormulateInput
              id="installment-min-sale-value"
              v-model.number="formInstallmentForm.minSaleValue"
              :label="$t('Valor mínimo')"
              type="text-number"
              :currency="$t('R$')"
              :precision="2"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { formulateValidation } from '@/mixins'
import { mapActions } from 'vuex'
import ESidebar from '@/views/components/ESidebar.vue'

const getInitialForm = () => ({
  id: null,
  index: null,
  installmentFrom: 0,
  installmentTo: 0,
  fee: 0,
  additionalFee: 0,
  minSaleValue: 0,
  active: true,
})

export default {
  components: { BRow, BCol, ESidebar },

  mixins: [formulateValidation],

  props: {
    isDebit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showSidebar: false,
      saving: false,
      loading: false,
      formInstallmentForm: getInitialForm(),
    }
  },

  computed: {
    edit() {
      return !!this.formInstallmentForm.id || !!this.formInstallmentForm.localId
    },
  },

  methods: {
    ...mapActions('pages/settings/financial/card/cardMaintain', {
      stAddInstallment: 'addInstallment',
      stUpdateInstallment: 'updateInstallment',
    }),

    async onShowSidebar(row = null) {
      try {
        this.formInstallmentForm = getInitialForm()
        this.showSidebar = true

        if (this.isDebit) {
          this.formInstallmentForm = {
            ...this.formInstallmentForm,
            installmentFrom: 1,
            installmentTo: 1,
          }
        }

        if (row?.item) {
          this.loading = true
          this.formInstallmentForm = { ...row.item }
        }
      } catch (error) {
        this.showGenericError({})
      } finally {
        this.loading = false
      }
    },

    async addInstallmentForm() {
      this.$refs.formInstallmentForm.showErrors()

      if (this.$refs.formInstallmentForm.hasErrors) {
        this.showInvalidDataMessage({})
        return
      }

      try {
        this.saving = true
        if (this.edit) {
          this.stUpdateInstallment({ data: this.formInstallmentForm })
        } else {
          this.stAddInstallment(this.formInstallmentForm)
        }
        this.showSidebar = false
      } catch (error) {
        this.showError({ message: error.message })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
